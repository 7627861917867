import React from 'react';
import {Link} from 'react-router-dom';
import {useStore} from 'effector-react';
import useSWR from 'swr';
import dayjs from 'dayjs';

import {$config} from '../../models/app/app';

import './NewsArticles.scss';

interface Props {
  section: string;
  item: any;
}

export default function NewsArticlesItem({section, item}: Props) {
  const {locale} = useStore($config);

  const tagsIds = item.tags.iv?.toString();
  const tags = useSWR(tagsIds ? `?ids=${tagsIds}` : null).data?.items;

  return (
    <div className="NewsArticlesItem">
      <p className="NewsArticlesItem-tags">
        {tags?.map((item: any) => (
          <span key={item.id}>#{item.data.text[locale]}</span>
        ))}
      </p>

      <div className="NewsArticlesItem-dateTitle">
        <p className="font-c NewsArticlesItem-date">{dayjs(item.date.iv).format('DD/MM/YYYY')}</p>
        <Link to={`/${section}/${item.slug.iv}`} state={true}>
          <h2 className="font-d NewsArticlesItem-title">
            <span>{item.title[locale]}</span>
            <svg viewBox="0 0 39 39">
              <use xlinkHref="#svg-arrow-top-right" />
            </svg>
          </h2>
        </Link>
      </div>
    </div>
  );
}
