import React from 'react';

import Head from './Head';

export default function ErrorPage() {
  return (
    <div
      role="alert"
      style={{
        paddingTop: '40vh',
        height: '100vh',
        textAlign: 'center',
      }}
    >
      <Head title="Error 404" />
      <div>
        <h1 style={{fontFamily: 'var(--medium)', fontSize: '2rem', marginBottom: '1rem'}}>Error 404</h1>
        <p style={{fontFamily: 'var(--light)', fontSize: '2rem', marginBottom: '1rem'}}>The page was not found.</p>
      </div>
    </div>
  );
}
