import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useParams} from 'react-router-dom';
import {useStore} from 'effector-react';
import useSWR from 'swr';
import {sanitizeUppercase} from '../../lib/utils';

import {$config} from '../../models/app/app';

import './Team.scss';
import AnimateIn from '../../components/AnimateIn';
import AnimateInList from '../../components/AnimateInList';
import Head from '../../components/Head';
import Image from '../../components/Image';

export default function PersonDetail() {
  const {t} = useTranslation();
  const {slug} = useParams();
  const {locale} = useStore($config);
  const [isReady, setisReady] = useState(false);

  const peopleIds = useSWR('/team-page')?.data?.items[0].data.people.iv;
  const people = useSWR(peopleIds ? `?ids=${peopleIds.toString()}` : null)?.data?.items;

  const currPersonIndex = people?.findIndex((item: any) => item.data.slug.iv === slug);
  const nextPersonIndex = currPersonIndex < peopleIds?.length - 1 ? currPersonIndex + 1 : 0;
  const prevPersonIndex = currPersonIndex > 0 ? currPersonIndex - 1 : peopleIds?.length - 1;

  const page = people ? people[currPersonIndex].data : null;
  const compCharacteristics = useSWR(page ? `/characteristics-comp` : null).data?.items[0].data;

  return (
    <div className="PersonDetail">
      {page && (
        <div style={{opacity: isReady ? 1 : 0, transition: '600ms var(--quad-out)'}}>
          <Head title={page.name[locale]} description={page.metaDescription[locale]} />

          <div className="PersonDetail-main">
            <div className="grid container --bp pad">
              <div className="rs-1 cs-1 ce-25 cs-l-9 mainControls">
                <Link className="mainControls__prev" to={`/team/${people[prevPersonIndex].data.slug.iv}`}>
                  <svg viewBox="0 0 39 39">
                    <use xlinkHref="#svg-arrow-right" />
                  </svg>
                </Link>
                <Link className="mainControls__next" to={`/team/${people[nextPersonIndex].data.slug.iv}`}>
                  <svg viewBox="0 0 39 39">
                    <use xlinkHref="#svg-arrow-right" />
                  </svg>
                </Link>
              </div>

              <div className="rs-2 cs-1 ce-25 cs-l-9 ce-l-16 mainInfo">
                <div className="mainInfo__head">
                  <h1 className="font-a">{page.name[locale]}</h1>
                  <p className="font-c">{sanitizeUppercase(page.role[locale])}</p>
                </div>

                {page.teaser[locale] && <p className="font-c mainInfo__teaser">{page.teaser[locale]}</p>}

                {page.practiceAreas.iv && (
                  <div className="mainInfo__practiceAreas">
                    <h2 className="font-f">{sanitizeUppercase(t('practice-areas'))}</h2>
                    {page.practiceAreas.iv.map((item: any) => (
                      <p key={item} className="font-b">
                        {t(item)}
                      </p>
                    ))}
                  </div>
                )}

                <div className="mainInfo__contact">
                  <h2 className="font-f">{sanitizeUppercase(t('contact'))}</h2>
                  <p className="font-c">{page.contact.iv}</p>
                </div>
              </div>

              <div className="rs-3 rs-l-2 cs-1 ce-25 ce-l-8 mainImage">
                <Image src={`${page.image.iv[0]}`} alt={page.name[locale]} onLoad={() => setisReady(true)} />
              </div>

              <div className="rs-4 rs-l-2 re-l-5 cs-1 ce-25 cs-l-17 mainBio">
                <div className="textblock" dangerouslySetInnerHTML={{__html: page.bio[locale]}} />
              </div>

              <div className="rs-5 rs-l-3 cs-1 ce-25 ce-l-15 mainIntro">
                <AnimateIn type="from-bottom">
                  <div className="font-e" dangerouslySetInnerHTML={{__html: page.intro[locale]}} />
                </AnimateIn>
              </div>
            </div>
          </div>

          <div className="PersonDetail-mainBelow">
            <div className="grid container --bp pad">
              <div className="rs-1 cs-1 ce-25 mainBelow-team">
                <AnimateIn type="from-bottom">
                  <h2 className="font-a">{t('our-team')}</h2>
                  <Link className="button" to="/team">
                    {t('gnoriste-mas')}
                  </Link>
                </AnimateIn>
              </div>

              <div className="rs-2 cs-1 ce-25 cs-l-17 mainBelow-compbox">
                {compCharacteristics && (
                  <AnimateInList>
                    <h2 className="font-b">{compCharacteristics.title[locale]}</h2>
                    <span className="font-a">{compCharacteristics.text1[locale]}</span>
                    <span className="font-a">{compCharacteristics.text2[locale]}</span>
                    <span className="font-a">{compCharacteristics.text3[locale]}</span>
                  </AnimateInList>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
