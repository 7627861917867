import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {motion} from 'framer-motion';
import {useStore} from 'effector-react';
import useSWR from 'swr';

import {$config} from '../../models/app/app';

import './PracticeAreas.scss';
import AnimateIn from '../../components/AnimateIn';
import AnimateInList from '../../components/AnimateInList';
import ArrowLink from '../../components/ArrowLink';
import Head from '../../components/Head';
import Image from '../../components/Image';

export default function PracticeAreas() {
  const {locale} = useStore($config);
  const [imagesLoaded, setimagesLoaded] = useState({1: false, 2: false, 3: false});
  const [isReady, setisReady] = useState(false);

  const page = useSWR('/practice-areas-page')?.data?.items[0].data;

  useEffect(() => {
    if (imagesLoaded[1] && imagesLoaded[2] && imagesLoaded[3]) setisReady(true);
  }, [imagesLoaded]);

  return (
    <div className="PracticeAreas">
      {page && (
        <div style={{opacity: isReady ? 1 : 0, transition: '600ms var(--quad-out)'}}>
          <Head title={page.title[locale]} description={page.metaDescription[locale]} />

          <motion.div className="PracticeAreas-top">
            <div className="grid container pad">
              <div className="rs-1 cs-1 ce-25 ce-m-8 topTitle">
                <h1 className="font-a1">{page.title[locale]}</h1>
              </div>

              <div className="rs-2 rs-m-1 cs-1 ce-25 cs-m-9 ce-m-19 topTeaser">
                <p className="font-a">{page.topTeaser[locale]}</p>
              </div>

              <div className="rs-3 cs-1 ce-25 ce-s-8 topBox">
                <Link to="employment-law">
                  <h2 className="font-b">{page.topbox1Title[locale]}</h2>
                  <AnimateIn type="clip">
                    <Image
                      src={`${page.topbox1Image.iv}`}
                      onLoad={() => setimagesLoaded((prevState) => ({...prevState, 1: true}))}
                    />
                  </AnimateIn>
                </Link>
              </div>

              <div className="rs-4 rs-s-3 cs-1 cs-s-9 ce-25 ce-s-16 topBox">
                <Link to="business-law">
                  <h2 className="font-b">{page.topbox2Title[locale]}</h2>
                  <AnimateIn type="clip">
                    <Image
                      src={`${page.topbox2Image.iv}`}
                      onLoad={() => setimagesLoaded((prevState) => ({...prevState, 2: true}))}
                    />
                  </AnimateIn>
                </Link>
              </div>

              <div className="rs-5 rs-s-3 cs-1 cs-s-17 ce-25 ce-s-24 topBox">
                <Link to="private-client-disputes">
                  <h2 className="font-b">{page.topbox3Title[locale]}</h2>
                  <AnimateIn type="clip">
                    <Image
                      src={`${page.topbox3Image.iv}`}
                      onLoad={() => setimagesLoaded((prevState) => ({...prevState, 3: true}))}
                    />
                  </AnimateIn>
                </Link>
              </div>

              <div className="rs-6 cs-1 cs-m-9 ce-25 ce-m-19 topIntro">
                <p className="font-b topIntro__title">{page.intro[locale][0].title}</p>
                <div className="textblock" dangerouslySetInnerHTML={{__html: page.intro[locale][0].description}} />
              </div>
            </div>
          </motion.div>

          <div className="PracticeAreas-main">
            <div className="grid container --bp pad">
              <div className="rs-1 cs-1 ce-24 cs-m-9 font-a mainTextbox --1">
                <AnimateInList>
                  <h2>{page.mainbox1[locale][0].title}</h2>
                  <p>
                    <ArrowLink to={page.mainbox1[locale][0].link1} text={page.mainbox1[locale][0].text1} />
                  </p>
                </AnimateInList>
              </div>

              <div className="rs-2 cs-1 ce-24 font-a mainTextbox --2">
                <AnimateInList>
                  <h2>{page.mainbox2[locale][0].title}</h2>
                  <p>
                    <ArrowLink to={page.mainbox2[locale][0].link1} text={page.mainbox2[locale][0].text1} />
                  </p>
                </AnimateInList>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
