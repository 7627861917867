import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useStore} from 'effector-react';
import {useForm} from 'react-hook-form';
import useSWR from 'swr';

import {$config, submitContactFormFx} from '../../models/app/app';

import './Contact.scss';
import AnimateIn from '../../components/AnimateIn';
import Head from '../../components/Head';

const _W: any = window;

export default function Contact() {
  const {locale} = useStore($config);

  const page = useSWR('/contact-page').data?.items[0].data;

  useEffect(() => {
    if (page) initMap();
  }, [page]);

  function initMap() {
    const mapElem1 = document.querySelector('.locationMap.--1');
    const mapElem2 = document.querySelector('.locationMap.--2');

    const contactInfo = page.contactInfo[locale];

    const mapOptions = {
      mapId: '9d48c32b0c2fecc7',
      zoom: 15,
      streetViewControl: false,
      mapTypeControlOptions: {
        style: _W.google.maps.MapTypeControlStyle.DROPDOWN_MENU,
      },
    };

    if (mapElem1) {
      const location1 = {lat: 40.63624, lng: 22.93696};
      const map1 = new _W.google.maps.Map(mapElem1, {...mapOptions, center: location1});
      const marker1 = new _W.google.maps.Marker({position: location1, map: map1});

      const infoWindowContent1 = `
            <div class="InfoWindow">
              <div class="InfoWindow-logo"></div>
              <div class="InfoWindow-address">
                ${contactInfo[0].address} ${contactInfo[0].city}
              </div>
            </div>
          `;

      const infoWindow1 = new _W.google.maps.InfoWindow({content: infoWindowContent1});

      marker1.addListener('click', () => {
        infoWindow1.open({map1, anchor: marker1});
      });
    }

    if (mapElem2) {
      const location2 = {lat: 37.98143, lng: 23.73677};
      const map2 = new _W.google.maps.Map(mapElem2, {...mapOptions, center: location2});
      const marker2 = new _W.google.maps.Marker({position: location2, map: map2});

      const infoWindowContent1 = `
            <div class="InfoWindow">
              <div class="InfoWindow-logo"></div>
              <div class="InfoWindow-address">
                ${contactInfo[1].address} ${contactInfo[1].city}
              </div>
            </div>
          `;

      const infoWindow1 = new _W.google.maps.InfoWindow({content: infoWindowContent1});

      marker2.addListener('click', () => {
        infoWindow1.open({map2, anchor: marker2});
      });
    }
  }

  return (
    <div className="Contact">
      {page && (
        <div className="grid container --bp pad dol">
          <div className="rs-1 cs-1 ce-25 ce-l-5 contactTitle">
            <Head title={page.title[locale]} description={page.metaDescription[locale]} />
            <h1 className="font-a1">{page.title[locale]}</h1>
          </div>

          <div className="rs-2 rs-l-1 cs-1 ce-25 cs-l-5 ce-l-21">
            <p className="font-a contactTeaser">{page.topTeaser[locale]}</p>
            <p className="font-c contactEmail">
              <a href="mailto:info@tarpinidis-law.gr">info@tarpinidis-law.gr</a>
            </p>

            <div className="contactLocations">
              {page.contactInfo[locale].map((item: any, i: number) => (
                <div key={i} className="location">
                  <AnimateIn type="from-bottom">
                    <div className="locationMeta">
                      <p className="font-c locationMeta__title">{item.city}</p>
                      <p className="font-a">T {item.phone}</p>
                    </div>
                    <div className={`locationMap --${i + 1}`}></div>
                  </AnimateIn>
                </div>
              ))}
            </div>

            <AnimateIn type="from-bottom">
              <ContactForm />
            </AnimateIn>
          </div>
        </div>
      )}
    </div>
  );
}

function ContactForm() {
  const {t} = useTranslation();
  const {register, handleSubmit, reset, formState} = useForm();

  const isSending = useStore(submitContactFormFx.pending);
  const [isFormSuccess, setisFormSuccess] = useState(false);

  const validateEmail = (email: string) => {
    return !!String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
  };

  const onSubmit = async (values: any) => {
    const res = await submitContactFormFx(values);
    if (res) {
      setisFormSuccess(true);
      reset();
    }
  };

  return (
    <div className="ContactForm">
      <button
        className="font-c ContactForm-success"
        style={{
          opacity: isFormSuccess ? 1 : 0,
          visibility: isFormSuccess ? 'visible' : 'hidden',
        }}
        onClick={() => setisFormSuccess(false)}
      >
        <p>{t('contact-form-success-message')}</p>
      </button>

      <div style={{opacity: isFormSuccess ? 0.2 : 1, transition: 'opacity 400ms var(--quad-out)'}}>
        <h2 className="font-c ContactForm-title">{t('contact-form')}</h2>

        <form className="ContactForm-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="form-control">
            <input {...register('name', {required: true})} type="text" placeholder={t('full-name')} />
            {formState.isSubmitted && formState.errors.name && (
              <div className="form-control-error">
                <p>!</p>
              </div>
            )}
          </div>

          <div className="form-control">
            <input
              {...register('email', {required: true, validate: (value) => validateEmail(value)})}
              type="text"
              placeholder="Email"
            />
            {formState.isSubmitted && formState.errors.email && (
              <div className="form-control-error">
                <p>!</p>
              </div>
            )}
          </div>

          <div className="form-control">
            <textarea {...register('message', {required: true})} placeholder={t('message')} />
            {formState.isSubmitted && formState.errors.message && (
              <div className="form-control-error">
                <p>!</p>
              </div>
            )}
          </div>

          <div className="form-action">
            <button className="button" type="submit" disabled={isSending || isFormSuccess}>
              {t('send')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
