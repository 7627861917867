import React from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useStore} from 'effector-react';
import useSWR from 'swr';
import dayjs from 'dayjs';

import {$config} from '../../models/app/app';

import './NewsArticles.scss';
import AnimateIn from '../../components/AnimateIn';
import Head from '../../components/Head';

interface Props {
  section: string;
}

export default function NewsArticlesDetail({section}: Props) {
  const {t} = useTranslation();
  const {state} = useLocation();
  const navigate = useNavigate();
  const {slug} = useParams();
  const {locale} = useStore($config);

  const page = useSWR(`/${section}?$filter=data/slug/iv eq '${slug}'`).data?.items[0].data;
  const tagsIds = page?.tags.iv?.toString();
  const tags = useSWR(tagsIds ? `?ids=${tagsIds}` : null).data?.items;

  return (
    <div className="NewsArticlesDetail">
      {page && (
        <div className="dol">
          <div className="grid container --bp pad">
            <div className="rs-1 cs-1 ce-25 ce-l-5 NewsArticlesDetail-title">
              <button className="titleBackButton" onClick={() => (state ? navigate(-1) : navigate(`/${section}`))}>
                <h2 className="font-a1">{t(section)}</h2>
                <div className="icon">
                  <svg viewBox="0 0 39 39">
                    <use xlinkHref="#svg-arrow-right" />
                  </svg>
                </div>
              </button>
            </div>

            <div className="rs-2 rs-l-1 cs-1 ce-25 cs-l-5 ce-l-21 NewsArticlesDetail-main">
              <AnimateIn type="from-bottom">
                <Head title={page.title[locale]} type="article" />

                <p className="NewsArticlesDetail-tags">
                  {tags?.map((item: any) => (
                    <span key={item.id}>#{item.data.text[locale]}</span>
                  ))}
                </p>

                <div className="NewsArticlesDetail-dateTitle">
                  <p className="font-c NewsArticlesDetail-date">{dayjs(page.date.iv).format('DD/MM/YYYY')}</p>
                  <h1 className="font-d NewsArticlesDetail-title">{page.title[locale]}</h1>
                </div>

                <div
                  className="textblock NewsArticlesDetail-description"
                  dangerouslySetInnerHTML={{__html: page.description[locale]}}
                />
              </AnimateIn>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
