import React, {useEffect, useCallback} from 'react';
import {useInView} from 'react-intersection-observer';
import {motion, useAnimation} from 'framer-motion';

type Props = {
  type?:
    | 'fade'
    | 'from-bottom'
    | 'from-top'
    | 'from-right'
    | 'from-left'
    | 'clip'
    | 'clip-right'
    | 'clip-bottom'
    | 'clip-top';
  children: JSX.Element | JSX.Element[];
};

export default function AnimateIn({type = 'fade', children}: Props) {
  const {ref, inView} = useInView({threshold: 0, delay: 400});
  const controls = useAnimation();

  const resetAnimation = useCallback(() => {
    controls.set({opacity: 0});

    switch (type) {
      case 'from-bottom':
        controls.set({y: 40});
        break;
      case 'from-top':
        controls.set({y: -40});
        break;
      case 'from-right':
        controls.set({x: 40});
        break;
      case 'from-left':
        controls.set({x: -40});
        break;
      case 'clip':
        controls.set({clipPath: 'inset(5% 5% 5% 5%)'});
        break;
      case 'clip-right':
        controls.set({clipPath: 'inset(0% 100% 0% 0%)'});
        break;
      case 'clip-bottom':
        controls.set({clipPath: 'inset(0% 0% 75% 0%)'});
        break;
      case 'clip-top':
        controls.set({clipPath: 'inset(75% 0% 0% 0%)'});
        break;
    }
  }, [type, controls]);

  const startAnimation = useCallback(() => {
    controls.start({opacity: 1, x: 0, y: 0, clipPath: 'inset(0% 0% 0% 0%)'});
  }, [controls]);

  useEffect(() => {
    resetAnimation();
  }, [resetAnimation]);

  useEffect(() => {
    inView ? startAnimation() : resetAnimation();
  }, [startAnimation, resetAnimation, inView]);

  return (
    <motion.div ref={ref} animate={controls} transition={{type: 'spring', mass: 1, damping: 30}}>
      {children}
    </motion.div>
  );
}
