import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {useStore} from 'effector-react';
import useSWR from 'swr';
import {useInView} from 'react-intersection-observer';

import {$config} from '../models/app/app';

import './Footer.scss';
import AnimateIn from './AnimateIn';

export default function Footer() {
  const {t} = useTranslation();
  const {locale} = useStore($config);
  const ref1 = useInView({threshold: 0, delay: 200});
  const ref2 = useInView({threshold: 0, delay: 200});

  const contactInfo = useSWR(`/contact-page`).data?.items[0].data.contactInfo[locale];

  return (
    <div className="Footer">
      <div ref={ref1.ref} className="Footer-top" style={{opacity: ref1.inView ? 1 : 0}}>
        <div className="font-a topContact">
          <AnimateIn type="from-bottom">
            <div className="container pad">
              <p>
                {t('contact-us').toLowerCase()}
                <br />
                <a href="mailto:info@tarpinidis-law.gr">info@tarpinidis-law.gr</a>
              </p>
            </div>
          </AnimateIn>
        </div>
      </div>

      <div ref={ref2.ref} className="Footer-bottom" style={{opacity: ref2.inView ? 1 : 0}}>
        <div className="container pad --tall">
          <div className="inner">
            <AnimateIn type="from-bottom">
              <div className="Footer-locations font-c">
                {contactInfo?.map((item: any, i: number) => (
                  <div key={i} className="location">
                    <p>
                      <strong>{item.city}</strong>
                    </p>
                    <p>{item.address}</p>
                    <p>
                      <strong>
                        T <a href={`tel:${item.phone}`}>{item.phone}</a>
                      </strong>
                    </p>
                    {item.phone2 && (
                      <p>
                        <strong>
                          T <a href={`tel:${item.phone2}`}>{item.phone2}</a>
                        </strong>
                      </p>
                    )}
                  </div>
                ))}
              </div>
            </AnimateIn>

            <AnimateIn type="fade">
              <div className="row">
                <div className="Footer-links">
                  <div>
                    <Link to="/terms-of-use">{t('terms-privacy')}</Link>
                  </div>
                  <div className="credits">
                    <a href="https://andia-angelidou.gr/" target="_blank" rel="noreferrer">
                      DESIGN A.
                    </a>
                  </div>
                </div>

                <div className="Footer-logo"></div>
              </div>
            </AnimateIn>
          </div>
        </div>
      </div>
    </div>
  );
}
