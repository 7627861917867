import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    supportedLngs: ['el', 'en'],
    lng: 'el',
    fallbackLng: 'el',
    defaultNS: 'translation',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      el: {
        translation: {
          page: 'Σελίδα',
        },
      },
      en: {
        translation: {
          page: 'Page',
        },
      },
    },
  });

export default i18n;
