import React, {CSSProperties} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {useStore} from 'effector-react';

import {$config} from '../models/app/app';

interface Props {
  title: string;
  sections: {
    slug: string;
    items: any[];
  }[];
}

export default function AsideItems({title, sections}: Props) {
  const {t} = useTranslation();
  const {locale} = useStore($config);

  return (
    <aside style={css.AsideItems}>
      <h2 style={css.AsideItems_title} className="font-c">
        {title}
      </h2>

      {sections.map(({slug, items}, i) => (
        <div key={i} style={css.AsideItems_section}>
          <h3 style={css.AsideItems_sectionTitle} className="font-b">
            {t(slug)}
          </h3>

          {items.map((item, i) => (
            <h4 key={i} style={css.AsideItems_itemTitle} className="font-c">
              <Link className="AsideItems-link" to={`/${slug}/${item.data.slug.iv}`}>
                <span className="AsideItems-text">{item.data.title[locale]}</span>
                <svg style={css.AsideItems_itemSvg} viewBox="0 0 39 39">
                  <use xlinkHref="#svg-arrow-top-right" />
                </svg>
              </Link>
            </h4>
          ))}
        </div>
      ))}
    </aside>
  );
}

const css: {[className: string]: CSSProperties} = {
  AsideItems_section: {
    marginBottom: '2rem',
  },

  AsideItems_title: {
    marginBottom: '0.25rem',
  },

  AsideItems_sectionTitle: {
    paddingRight: '2rem',
  },

  AsideItems_itemSvg: {
    width: '2.15rem',
    height: '2.15rem',
    marginTop: '.5rem',
  },
};
