import React, {useEffect, useState, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import useSWR from 'swr';
import {useStore} from 'effector-react';
import {motion} from 'framer-motion';
import {sanitizeUppercase} from '../../lib/utils';
import settings, {appName} from '../../config/settings';

import {$config} from '../../models/app/app';

import './Home.scss';
import AnimateIn from '../../components/AnimateIn';
import AnimateInList from '../../components/AnimateInList';
import ArrowLink from '../../components/ArrowLink';
import AsideItems from '../../components/AsideItems';
import Head from '../../components/Head';
import Image from '../../components/Image';

const autoplaySpeed = 4000;
let autoplayTimer: any;
let activeSlideIndex = 0;

export default function Home() {
  const {t} = useTranslation();
  const {locale} = useStore($config);
  const [slideIndex, setslideIndex] = useState(0);
  const [isReady, setisReady] = useState(false);

  const page = useSWR('/home-page').data?.items[0].data;
  const slides = page?.sliderTexts[locale];

  const compbox1 = useSWR(page ? `?ids=${page.compbox1.iv[0]}` : null).data?.items[0].data;
  const compbox2 = useSWR(page ? `?ids=${page.compbox2.iv[0]}` : null).data?.items[0].data;
  const compbox3 = useSWR(page ? `?ids=${page.compbox3.iv[0]}` : null).data?.items[0].data;

  const asideRulings = useSWR(page && page.asideRulings.iv[0] ? `?ids=${page.asideRulings.iv[0]}` : null).data?.items;
  const asideArticles = useSWR(page && page.asideArticles.iv[0] ? `?ids=${page.asideArticles.iv[0]}` : null).data
    ?.items;

  const asideItems = useMemo(() => {
    let items = [];
    if (asideRulings?.length) items.push({slug: 'rulings', items: asideRulings});
    if (asideArticles?.length) items.push({slug: 'articles', items: asideArticles});
    return items.length ? items : null;
  }, [asideRulings, asideArticles]);

  useEffect(() => {
    function startAutoplayTimer() {
      clearInterval(autoplayTimer);
      autoplayTimer = setInterval(updateSlideIndex, autoplaySpeed);
    }

    function updateSlideIndex() {
      let index = 0;
      if (activeSlideIndex < slides.length - 1) {
        index = activeSlideIndex + 1;
      }
      activeSlideIndex = index;
      setslideIndex(index);
    }

    if (slides) startAutoplayTimer();

    return () => {
      clearInterval(autoplayTimer);
    };
  }, [slides]);

  return (
    <div className="Home">
      {page && (
        <div style={{opacity: isReady ? 1 : 0, transition: '600ms var(--quad-out)'}}>
          <Head title="" description={page.metaDescription[locale]} />
          <h1 className="invisible">{appName[locale]}</h1>

          <div className="Home-top">
            <div className="grid container --bp pad">
              <div className="cs-1 ce-25 cs-l-9 topSlider">
                {slides?.map((item: any, i: number) => (
                  <Slide key={i} item={item} isActive={i === slideIndex} />
                ))}
              </div>

              <div className="rs-2 cs-1 ce-25 cs-l-9 topImage">
                <AnimateIn type="clip">
                  <Image
                    src={`${page.topImage.iv[0]}?width=2000`}
                    srcSet={`
                    ${settings.imgixUrl}/${page.topImage.iv[0]}?width=800&auto=format 800w,
                    ${settings.imgixUrl}/${page.topImage.iv[0]}?width=1280&auto=format 1280w,
                    ${settings.imgixUrl}/${page.topImage.iv[0]}?width=2000&auto=format 2000w
                  `}
                    sizes={`
                      (max-width: 768px) calc(100vw - 4rem),
                      (max-width: 1280px) 600px,
                      2000px
                    `}
                    onLoad={() => setisReady(true)}
                  />
                </AnimateIn>
              </div>

              <aside className="rs-3 rs-l-2 cs-1 ce-25 ce-l-8 topAsideItems">
                {asideItems && <AsideItems title={sanitizeUppercase(t('diabaste'))} sections={asideItems} />}
              </aside>
            </div>
          </div>

          <div className="Home-main">
            <div className="grid container --bp pad">
              <div className="cs-1 cs-l-9 ce-25 ce-xs-18 ce-s-16 ce-l-20 ce-xl-17 mainIntro">
                <h2 className="font-b">{page.intro[locale][0].title}</h2>
                <div className="textblock" dangerouslySetInnerHTML={{__html: page.intro[locale][0].description}} />
              </div>

              <div className="rs-2 cs-1 ce-13 ce-l-8 mainImagebox --1">
                <AnimateIn type="clip">
                  <Image src={`${page.imagebox1.iv[0]}?width=720`} loading="lazy" />
                </AnimateIn>
              </div>

              <div className="rs-3 cs-1 cs-l-19 ce-25 mainTextbox --1">
                {compbox1 && (
                  <div className="inner">
                    <AnimateInList>
                      <h2 className="font-b">{compbox1.title[locale]}</h2>
                      <span className="font-a">{compbox1.text1[locale]}</span>
                      <span className="font-a">{compbox1.text2[locale]}</span>
                      <span className="font-a">{compbox1.text3[locale]}</span>
                    </AnimateInList>
                  </div>
                )}
              </div>

              <div className="rs-4 cs-1 cs-l-3 ce-13 ce-l-10 mainImagebox --2">
                <AnimateIn type="clip">
                  <Image src={`${page.imagebox2.iv[0]}?width=720`} loading="lazy" />
                </AnimateIn>
              </div>

              <div className="rs-5 cs-1 cs-l-3 ce-24 mainTextbox --2">
                {compbox2 && (
                  <AnimateInList>
                    <h2 className="font-b">{compbox2.title[locale]}</h2>
                    <span className="font-a">
                      <ArrowLink to={compbox2.link1.iv} text={compbox2.text1[locale]} />
                    </span>
                    <span className="font-a">
                      <ArrowLink to={compbox2.link2.iv} text={compbox2.text2[locale]} />
                    </span>
                    <span className="font-a">
                      <ArrowLink to={compbox2.link3.iv} text={compbox2.text3[locale]} />
                    </span>
                  </AnimateInList>
                )}
              </div>

              <div className="rs-6 cs-1 cs-l-15 ce-24 mainTextbox --3">
                {compbox3 && (
                  <AnimateInList>
                    <h2 className="font-b">{compbox3.title[locale]}</h2>
                    <span className="font-a">{compbox3.text1[locale]}</span>
                    <span className="font-a">{compbox3.text2[locale]}</span>
                    <span className="font-a">{compbox3.text3[locale]}</span>
                  </AnimateInList>
                )}
              </div>

              <div className="rs-7 cs-1 cs-l-3 ce-25 ce-l-23 mainTeam">
                <AnimateIn type="from-top">
                  <h2 className="font-a">{page.teamTitle[locale]}</h2>
                  <Link className="button" to="/team">
                    {t('gnoriste-mas')}
                  </Link>
                </AnimateIn>
                <AnimateIn type="clip">
                  <img
                    alt="Tarpinidis Team"
                    src={`https://cms.greymatter.gr/api/assets/tarpinidis/d6c0eef3-a613-47e9-81e2-ff3ced88b736/team.webp`}
                    loading="lazy"
                  />
                </AnimateIn>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const Slide = ({item, isActive}: {item: any; isActive: boolean}) => {
  const {t} = useTranslation();

  return (
    <motion.div
      className="Slide"
      initial={
        isActive
          ? {opacity: 1, y: 0, clipPath: 'inset(0% 0% 0% 0%)'}
          : {opacity: 0, y: 0, clipPath: 'inset(0% 100% 0% 0%)'}
      }
      animate={
        isActive
          ? {opacity: 1, y: 0, clipPath: 'inset(0% 0% 0% 0%)'}
          : {opacity: 0, y: 0, clipPath: 'inset(0% 100% 0% 0%)'}
      }
      transition={isActive ? {duration: 1.8, ease: 'circOut'} : {duration: 0.6, ease: 'circOut'}}
    >
      <div className="font-a Slide__teaser">{item.text}</div>

      <Link className="button Slide__button" to="/company">
        {t('mathete-gia-mas')}
      </Link>
    </motion.div>
  );
};
