import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {useStore} from 'effector-react';
import {sanitizeUppercase} from '../lib/utils';

import {$config} from '../models/app/app';

import './PersonItem.scss';
import Image from './Image';

export default function PersonItem({item, onLoad}: {item: any; onLoad?: () => void}) {
  const {locale} = useStore($config);

  const {image, name, role, slug} = item;

  return (
    <Link className="PersonItem" to={`/team/${slug.iv}`}>
      <div className="PersonItem-image">
        <Image
          style={{aspectRatio: '202 / 255', backgroundColor: 'rgba(243, 235, 225, .6)'}}
          src={`${image.iv[0]}`}
          alt={name[locale]}
          onLoad={() => {
            if (onLoad) onLoad();
          }}
        />
      </div>

      <div className="PersonItem-meta">
        <h3 className="font-e">{name[locale]}</h3>
        <p className="font-c">{sanitizeUppercase(role[locale])}</p>
        <svg viewBox="0 0 39 39">
          <use xlinkHref="#svg-arrow-top-right" />
        </svg>
      </div>
    </Link>
  );
}
