import {createEvent, createEffect, createStore, attach, forward} from 'effector';
import axios from 'axios';
import i18next from 'i18next';
import settings from '../../config/settings';

import {Config} from './app_types';

/**
 * Stores
 */
export const $config = createStore<Config>({
  locale: 'el',
  squidexToken: '',
  isInitComplete: false,
});

/**
 * Config
 */
export const initApp = createEvent();
export const setLocale = createEvent<Config['locale']>();
export const setSquidexToken = createEvent<Config['squidexToken']>();
const setIsInitComplete = createEvent<boolean>();
const supportedLocales = ['el', 'en'];

// Init Squidex
export const initSquidexFx = createEffect(async () => {
  const url = `${settings.baseUrl}/identity-server/connect/token`;
  const data = `grant_type=client_credentials&client_id=${settings.squidexClient.id}&client_secret=${settings.squidexClient.secret}&scope=squidex-api`;
  const res = await axios.post(url, data);
  return res.data.access_token;
});

// Init Labels
const initLabels = createEffect(async (params: Config) => {
  const res = await axios.get('/labels', {
    baseURL: settings.contentApi,
    headers: {Authorization: `Bearer ${params.squidexToken}`},
  });

  if (res) {
    let labels = {el: {}, en: {}};
    supportedLocales.forEach((locale) => {
      res.data.items.map((item: any) => {
        if (locale === 'el') {
          labels.el = {...labels.el, [item.data.slug.iv]: item.data.text[locale]};
        }
        if (locale === 'en') {
          labels.en = {...labels.en, [item.data.slug.iv]: item.data.text[locale]};
        }
        return labels;
      });
    });

    i18next.addResourceBundle('el', 'translation', labels.el);
    i18next.addResourceBundle('en', 'translation', labels.en);
    setIsInitComplete(true);
  }
});

export const initLabelsFx = attach({
  source: $config,
  effect: initLabels,
});

$config
  .on(setLocale, (state, locale) => ({...state, locale}))
  .on(initSquidexFx.doneData, (state, squidexToken) => ({...state, squidexToken}))
  .on(setIsInitComplete, (state, isInitComplete) => ({...state, isInitComplete}));

/**
 * Nav
 */
export const setNavIsOpen = createEvent<boolean>();
export const $navIsOpen = createStore(false).on(setNavIsOpen, (_, isOpen) => isOpen);

/**
 * Contact form
 */
export const submitContactFormFx = createEffect(async (params: any) => {
  const data = {
    name: {iv: params.name},
    email: {iv: params.email},
    message: {iv: params.message},
  };

  const res = await axios.post(`contact-form`, data);
  return res.status === 201;
});

/**
 * Conditional actions
 * ========================================================
 */
forward({
  from: initApp,
  to: initSquidexFx,
});

forward({
  from: initSquidexFx.doneData,
  to: initLabelsFx,
});
