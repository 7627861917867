import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {NavLink, useLocation} from 'react-router-dom';
import {useStore} from 'effector-react';
import {motion} from 'framer-motion';

import {$navIsOpen, setNavIsOpen} from '../models/app/app';

import './Nav.scss';

export default function Nav() {
  const {t} = useTranslation();
  const {pathname} = useLocation();
  const navIsOpen = useStore($navIsOpen);
  const [initialPath, setInitialPath] = useState('');

  useEffect(() => {
    document.body.style.overflow = navIsOpen ? 'hidden' : 'auto';
  }, [navIsOpen]);

  useEffect(() => {
    if (initialPath) {
      if (initialPath !== pathname) setNavIsOpen(false);
    } else {
      setInitialPath(pathname);
    }
  }, [initialPath, pathname]);

  const GoTo = ({to, text, index}: {to: string; text: string; index: number}) => {
    return (
      <NavLink to={to} end={true} onClick={() => setNavIsOpen(false)}>
        <motion.div
          style={{transformOrigin: 'left top'}}
          initial={{opacity: 0, y: 40 + index * 2}}
          animate={navIsOpen ? {opacity: 1, y: 0} : {opacity: 0, y: 40}}
          transition={{delay: index * 0.04, type: 'spring', mass: 1, damping: 20}}
        >
          {text}
        </motion.div>
      </NavLink>
    );
  };

  return (
    <motion.div className={`Nav ${navIsOpen ? 'is-open' : ''}`}>
      <div className="container --tall pad">
        <div className="Nav-inner">
          <motion.div className="Nav-close" initial={{opacity: 0}} animate={{opacity: 1}}>
            <button onClick={() => setNavIsOpen(false)}>
              <svg viewBox="0 0 18 18">
                <use xlinkHref="#svg-close" />
              </svg>
            </button>
          </motion.div>

          <nav>
            <div className="Nav-cols">
              <div className="col font-a">
                <GoTo to="/" text={t('home')} index={0} />
                <GoTo to="/company" text={t('company')} index={1} />
                <GoTo to="/team" text={t('team')} index={2} />
              </div>

              <div className="col font-a">
                <GoTo to="/practice-areas" text={t('practice-areas')} index={0} />

                <div className="subcats font-b">
                  <GoTo to="/practice-areas/employment-law" text={t('employment-law')} index={1} />
                  <GoTo to="/practice-areas/business-law" text={t('business-law')} index={2} />
                  <GoTo to="/practice-areas/private-client-disputes" text={t('private-client-disputes')} index={3} />
                </div>

                <GoTo to="/rulings" text={t('rulings')} index={4} />
                <GoTo to="/articles" text={t('articles')} index={5} />
                <GoTo to="/news" text={t('news')} index={6} />
                <GoTo to="/contact" text={t('contact')} index={7} />
              </div>
            </div>
          </nav>

          <motion.div className="Nav-logo" animate={{opacity: navIsOpen ? 1 : 0}}></motion.div>
        </div>
      </div>
    </motion.div>
  );
}
