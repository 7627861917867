import React, {useEffect} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {Routes, Route, useLocation} from 'react-router-dom';

import ErrorFallback from './components/ErrorFallback';
import ErrorPage from './components/ErrorPage';

import Header from './components/Header';
import Footer from './components/Footer';
import Nav from './components/Nav';
import ESPA from './components/ESPA';

import Company from './pages/Company/Company';
import Contact from './pages/Contact/Contact';
import Home from './pages/Home/Home';
import NewsArticles from './pages/NewsArticles/NewsArticles';
import NewsArticlesDetail from './pages/NewsArticles/NewsArticlesDetail';
import PracticeAreas from './pages/PracticeAreas/PracticeAreas';
import PracticeAreasSection from './pages/PracticeAreas/PracticeAreasSection';
import Team from './pages/Team/Team';
import PersonDetail from './pages/Team/PersonDetail';
import Generic from './pages/Generic/Generic';

export default function AppInner() {
  const {pathname} = useLocation();

  useEffect(() => {
    let bg = '243, 235, 225'; // fantasy
    if (pathname.startsWith('/practice-areas')) bg = '220, 233, 241'; // blue
    if (pathname.startsWith('/rulings') || pathname.startsWith('/articles') || pathname.startsWith('/news'))
      bg = '253, 228, 225'; // chablis
    document.documentElement.style.setProperty('--page-bg', bg);
  }, [pathname]);

  return (
    <div className="AppInner">
      <Header />

      <Nav />

      <ESPA />

      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <main className="Main">
          <Routes>
            <Route path="/" element={<Home />} />

            <Route path="/practice-areas" element={<PracticeAreas />} />
            <Route path="/practice-areas/employment-law" element={<PracticeAreasSection section="employment-law" />} />
            <Route path="/practice-areas/business-law" element={<PracticeAreasSection section="business-law" />} />
            <Route
              path="/practice-areas/private-client-disputes"
              element={<PracticeAreasSection section="private-client-disputes" />}
            />

            <Route path="/company" element={<Company />} />

            <Route path="/team" element={<Team />} />
            <Route path="/team/:slug" element={<PersonDetail />} />

            <Route path="/rulings" element={<NewsArticles section="rulings" />} />
            <Route path="/rulings/:slug" element={<NewsArticlesDetail section="rulings" />} />

            <Route path="/articles" element={<NewsArticles section="articles" />} />
            <Route path="/articles/:slug" element={<NewsArticlesDetail section="articles" />} />

            <Route path="/news" element={<NewsArticles section="news" />} />
            <Route path="/news/:slug" element={<NewsArticlesDetail section="news" />} />

            <Route path="/contact" element={<Contact />} />

            <Route path="/terms-of-use" element={<Generic slug="terms-of-use" />} />
            <Route path="/privacy-policy" element={<Generic slug="privacy-policy" />} />

            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </main>
      </ErrorBoundary>

      <Footer />
    </div>
  );
}
