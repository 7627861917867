import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useStore} from 'effector-react';
import useSWR from 'swr';

import {$config} from '../../models/app/app';

import './Company.scss';
import AnimateIn from '../../components/AnimateIn';
import AnimateInList from '../../components/AnimateInList';
import ArrowLink from '../../components/ArrowLink';
import Head from '../../components/Head';
import Image from '../../components/Image';

export default function Company() {
  const {t} = useTranslation();
  const {locale} = useStore($config);
  const [isReady, setisReady] = useState(false);

  const page = useSWR('/company-page')?.data?.items[0].data;
  const compbox1 = useSWR(page ? `?ids=${page.compbox1.iv[0]}` : null).data?.items[0].data;

  return (
    <div className="Company">
      {page && (
        <div style={{opacity: isReady ? 1 : 0, transition: '600ms var(--quad-out)'}}>
          <Head title={page.title[locale]} description={page.metaDescription[locale]} />

          <div className="Company-top">
            <div className="grid container --bp pad">
              <div className="rs-1 rs-l-3 cs-1 cs-l-5 ce-25 topTitle">
                <h1 className="font-a1">{page.title[locale]}</h1>
              </div>

              <div className="rs-2 rs-l-1 cs-1 ce-25 ce-l-11 topTeaser">
                <p className="font-a">{page.topTeaser[locale]}</p>
              </div>

              <div className="rs-3 rs-l-1 cs-1 cs-l-14 ce-25 topImage">
                <AnimateIn type="clip">
                  <Image src={`${page.topImage.iv[0]}`} onLoad={() => setisReady(true)} />
                </AnimateIn>
              </div>

              <div className="rs-4 cs-1 cs-l-5 ce-25 ce-l-13 topIntro">
                <div className="textblock" dangerouslySetInnerHTML={{__html: page.intro[locale]}} />
              </div>
            </div>
          </div>

          <div className="Company-main">
            <div className="grid container --bp pad">
              <div className="cs-1 cs-l-7 ce-25 ce-l-19 mainText">
                <AnimateIn type="fade">
                  <h2 className="font-a1 mainTextTitle">{t('why-us')},</h2>
                </AnimateIn>

                <div>
                  {page.mainText[locale].map((item: any, i: number) => (
                    <div key={i} className="mainTextItem">
                      <AnimateIn type="fade">
                        <h3 className="font-a mainTextItem__title">{item.title}</h3>
                      </AnimateIn>
                      <AnimateIn type="from-right">
                        <div className="mainTextItem__description">
                          <div className="button --circle">{i + 1}</div>
                          <div className="textblock" dangerouslySetInnerHTML={{__html: item.description}} />
                        </div>
                      </AnimateIn>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="Company-mainBelow">
            <div className="grid container --bp pad">
              <div className="rs-1 cs-1 ce-25 cs-l-15 mainBelowTextbox">
                {compbox1 && (
                  <AnimateInList>
                    <h2 className="font-b">{compbox1.title[locale]}</h2>
                    <span className="font-a">
                      <ArrowLink to={compbox1.link1.iv} text={compbox1.text1[locale]} />
                    </span>
                    <span className="font-a">
                      <ArrowLink to={compbox1.link2.iv} text={compbox1.text2[locale]} />
                    </span>
                    <span className="font-a">
                      <ArrowLink to={compbox1.link3.iv} text={compbox1.text3[locale]} />
                    </span>
                  </AnimateInList>
                )}
              </div>

              <div className="rs-2 cs-1 ce-16 ce-l-8 mainBelowImagebox">
                <AnimateIn type="clip">
                  <Image src={page.imagebox1.iv} loading="lazy" />
                </AnimateIn>
              </div>

              <div className="rs-3 cs-1 ce-25 ce-xs-18 cs-l-9 ce-l-16">
                <AnimateIn type="from-bottom">
                  <div className="textblock" dangerouslySetInnerHTML={{__html: page.textbox2[locale]}} />
                </AnimateIn>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
