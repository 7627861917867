import React, {useState} from 'react';
import {useStore} from 'effector-react';
import useSWR from 'swr';

import {$config} from '../../models/app/app';

import './Team.scss';
import AnimateIn from '../../components/AnimateIn';
import Head from '../../components/Head';
import PersonItem from '../../components/PersonItem';

export default function Team() {
  const {locale} = useStore($config);

  let peopleIds;
  const page = useSWR('/team-page')?.data?.items[0].data;
  if (page) peopleIds = page.people.iv.toString();
  const people = useSWR(peopleIds ? `?ids=${peopleIds}` : null)?.data?.items;

  return (
    <div className="Team">
      {page && people && (
        <div className="dol">
          <Head title={page.title[locale]} description={page.metaDescription[locale]} />

          <div className="grid container --bp pad">
            <div className="rs-1 cs-1 ce-25 ce-l-8 teamTitle">
              <h1 className="font-a1">{page.title[locale]}</h1>
            </div>

            <div className="rs-2 rs-l-1 cs-1 ce-25 cs-l-9 ce-l-22 teamTeaser">
              <p className="font-a">{page.topTeaser[locale]}</p>
            </div>

            <div className="rs-3 rs-l-2 cs-1 ce-25 ce-l-8 teamAsideTeaser">
              <p className="font-c">{page.asideTeaser[locale]}</p>
            </div>

            <div className="rs-4 rs-l-2 cs-1 ce-25 cs-l-9 ce-l-23 teamPeople">
              <div className="person-items-grid">
                {people.map((item: any) => (
                  <AnimateIn key={item.id} type="from-bottom">
                    <PersonItem item={item.data} />
                  </AnimateIn>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
