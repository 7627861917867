import React, {useState, CSSProperties} from 'react';
import useSWR from 'swr';
import {useStore} from 'effector-react';
import settings from '../config/settings';

import {$config} from '../models/app/app';

interface Props extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {}

export default function Image(props: Props) {
  const {locale} = useStore($config);
  const [isReady, setIsReady] = useState(false);

  const style: CSSProperties = {
    ...props.style,
    opacity: isReady ? 1 : 0,
    transition: 'opacity 600ms var(--quad-out)',
  };

  const metadata = useSWR(`${settings.assetsMetaApi}/${props.src}`).data?.metadata;
  const alt = props.alt || (metadata && metadata[locale]) || '';
  const src = props.src?.includes('?')
    ? `${settings.imgixUrl}/${props.src}&auto=format`
    : `${settings.imgixUrl}/${props.src}?auto=format`;

  return (
    <img
      {...props}
      alt={alt}
      src={src}
      style={style}
      onLoad={(e) => {
        if (props.onLoad) props.onLoad(e);
        setIsReady(true);
      }}
      onError={(e) => {
        if (props.onError) props.onError(e);
        setIsReady(true);
      }}
    />
  );
}
