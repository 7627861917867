import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useLocation} from 'react-router-dom';
import {useStore} from 'effector-react';
import {motion} from 'framer-motion';

import {setNavIsOpen, $config} from '../models/app/app';

import './Header.scss';

export default function Header() {
  const {pathname} = useLocation();
  const {t} = useTranslation();
  const {locale} = useStore($config);
  const [stickyNavVisible, setStickyNavVisible] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const onScroll = () => {
    setStickyNavVisible(window.scrollY > 200);
  };

  const onLocaleChange = () => {
    const url = locale === 'el' ? 'en' + pathname : pathname;
    window.location.pathname = url;
  };

  return (
    <>
      <div className="Header">
        <div className="container pad">
          <div className="Header-inner">
            <Link
              className="Header-logo"
              to="/"
              aria-label={t('home')}
              style={{
                backgroundImage: locale === 'el' ? `url(/brand/logo-brand.svg)` : `url(/brand/logo-brand-en.svg)`,
              }}
            >
              <span className="invisible">{t('home')}</span>
            </Link>

            <div className="Header-extras">
              <button className="button --circle language-button" aria-label="language" onClick={onLocaleChange}>
                {locale === 'el' ? 'en' : 'el'}
              </button>

              <Link className="button contact-button" to="/contact">
                {t('contact').toLowerCase()}
              </Link>

              <button className="nav-button" aria-label="navigation" onClick={() => setNavIsOpen(true)}>
                <svg viewBox="0 0 49 29">
                  <use xlinkHref="#svg-nav-button" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      {stickyNavVisible && (
        <motion.div
          className="StickyNav"
          initial={{opacity: 0, y: 64}}
          animate={{opacity: 1, y: 0}}
          transition={{duration: 0.6, ease: 'circOut'}}
        >
          <div className="container pad">
            <button aria-label="navigation" onClick={() => setNavIsOpen(true)}>
              <svg viewBox="0 0 49 29">
                <use xlinkHref="#svg-nav-button" />
              </svg>
            </button>
          </div>
        </motion.div>
      )}
    </>
  );
}
