import React, {useEffect, useCallback} from 'react';
import {useInView} from 'react-intersection-observer';
import {motion, useAnimation} from 'framer-motion';

type Props = {
  children: JSX.Element[];
};

export default function AnimateInList({children}: Props) {
  const {ref, inView} = useInView({threshold: 0, delay: 400});
  const controls = useAnimation();

  const exitAnimation = useCallback(() => {
    controls.set({opacity: 0, y: 40});
  }, [controls]);

  const enterAnimation = useCallback(() => {
    controls.start((i) => ({
      opacity: 1,
      y: 0,
      transition: {delay: i * 0.1, type: 'spring', mass: 1, damping: 30},
    }));
  }, [controls]);

  useEffect(() => {
    exitAnimation();
  }, [exitAnimation]);

  useEffect(() => {
    inView ? enterAnimation() : exitAnimation();
  }, [enterAnimation, exitAnimation, inView]);

  return (
    <motion.ul ref={ref} className="AnimateInList" animate={controls}>
      {children?.map((item, i) => (
        <motion.li key={i} custom={i + 1} animate={controls}>
          {item}
        </motion.li>
      ))}
    </motion.ul>
  );
}
