import React from 'react';
import Helmet from 'react-helmet';
import {useStore} from 'effector-react';
import {appName} from '../config/settings';

import {$config} from '../models/app/app';

interface Props {
  title: string;
  description?: string;
  image?: string;
  type?: string;
}

export default function Head(props: Props) {
  const {title, description, image, type} = props;
  const {locale} = useStore($config);

  const homepageSuffix = locale === 'el' ? `Εταιρεία Δικηγόρων` : `Law Firm`;

  const titleTemplate = title ? `${title} | ${appName[locale]}` : `${appName[locale]} | ${homepageSuffix}`;
  const defaultImage = window.location.origin + '/share.jpg';
  const defaultType = 'website';

  return (
    <Helmet>
      <html lang={locale} />
      <title>{titleTemplate}</title>
      <meta name="description" content={description} />

      <meta itemProp="name" content={titleTemplate} />
      <meta itemProp="description" content={description} />
      <meta itemProp="image" content={image || defaultImage} />

      <meta property="og:title" content={titleTemplate} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image || defaultImage} />
      <meta property="og:type" content={type || defaultType} />
      <meta property="og:url" content={window.location.href} />
      <meta property="site_name" content={appName[locale]} />

      <link rel="canonical" href={`${window.location.origin}${window.location.pathname}`} />
    </Helmet>
  );
}
