import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useStore} from 'effector-react';
import {Link} from 'react-router-dom';
import useSWR from 'swr';
import {sanitizeUppercase} from '../../lib/utils';

import {$config} from '../../models/app/app';

import './PracticeAreasSection.scss';
import AnimateIn from '../../components/AnimateIn';
import AsideItems from '../../components/AsideItems';
import Head from '../../components/Head';
import Image from '../../components/Image';
import PersonItem from '../../components/PersonItem';

interface Props {
  section: 'employment-law' | 'business-law' | 'private-client-disputes';
}

export default function PracticeAreasSection({section}: Props) {
  const {t} = useTranslation();
  const {locale} = useStore($config);
  const [isReady, setisReady] = useState(false);

  let nextSection: typeof section;

  switch (section) {
    case 'employment-law':
      nextSection = 'business-law';
      break;
    case 'business-law':
      nextSection = 'private-client-disputes';
      break;
    case 'private-client-disputes':
      nextSection = 'employment-law';
      break;
  }

  let keyContactsIds = '';
  const page = useSWR(`${section}-page`)?.data?.items[0].data;
  if (page) keyContactsIds = page.keyContacts.iv.toString();
  const keyContacts = useSWR(`?ids=${keyContactsIds}`)?.data?.items;

  const sectionTag = useSWR(`/tags?$filter=data/slug/iv eq '${section}'`).data?.items[0].id;
  const sectionAsideItems = useSWR(
    sectionTag ? `/rulings?$filter=data/tags/iv eq '${sectionTag}'&$orderby=data/date/iv desc&$top=1` : null,
  ).data?.items;

  return (
    <div className="PracticeAreasSection">
      {page && (
        <div style={{opacity: isReady ? 1 : 0, transition: '600ms var(--quad-out)'}}>
          <Head title={page.title[locale]} description={page.metaDescription[locale]} />

          <div className="PracticeAreasSection-top">
            <div className="grid container --bp pad">
              <div className="rs-1 cs-1 ce-25 ce-l-8 topTitle">
                <h1 className="font-a1">{page.title[locale]}</h1>
              </div>

              <div className="rs-2 rs-l-1 cs-1 ce-25 cs-l-9 ce-l-22 topTeaser">
                <p className="font-a">{page.topTeaser[locale]}</p>
              </div>

              <div className="rs-3 re-l-6 cs-1 ce-25 ce-l-8 topAsideTeaser">
                <div className="font-c" dangerouslySetInnerHTML={{__html: page.asideTeaser[locale]}} />
              </div>

              <div className="rs-4 rs-l-3 cs-1 cs-l-9 ce-25 ce-l-16 topImage">
                <AnimateIn type="clip">
                  <Image src={`${page.topImage.iv}`} onLoad={() => setisReady(true)} />
                </AnimateIn>
              </div>

              <div className="rs-5 cs-1 cs-l-9 ce-25 ce-l-19 topDescription">
                {page.section1Title && <h2>{page.section1Title[locale]}</h2>}

                {page.section1Items && (
                  <div className="section">
                    {page.section1Items[locale].map((item: any, i: number) => (
                      <SectionItem key={i} item={item} />
                    ))}
                  </div>
                )}

                {page.description[locale] && (
                  <div className="textblock" dangerouslySetInnerHTML={{__html: page.description[locale]}} />
                )}
              </div>

              <div className="rs-6 rs-l-5 cs-1 cs-l-17 ce-25 topButtonNext">
                <Link className="button-with-arrow" to={`/practice-areas/${nextSection}`}>
                  <span className="button">{t(nextSection)}</span>
                  <svg viewBox="0 0 39 39">
                    <use xlinkHref="#svg-arrow-right" />
                  </svg>
                </Link>
              </div>

              <div className="rs-7 rs-l-5 cs-1 ce-25 ce-l-8 topAsideItems">
                {sectionAsideItems?.length > 0 && (
                  <AsideItems
                    title={sanitizeUppercase(t('diabaste'))}
                    sections={[
                      {
                        slug: 'rulings',
                        items: sectionAsideItems,
                      },
                    ]}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="PracticeAreasSection-main">
            <div className="grid container --bp pad">
              <div className="rs-1 cs-1 ce-8 mainTitle">
                <h2 className="button">{t('key-contacts')}</h2>
              </div>

              <div className="rs-2 rs-l-1 cs-1 cs-l-9 ce-25">
                <div className="person-items-grid">
                  {keyContacts?.map((item: any) => (
                    <AnimateIn key={item.id} type="from-bottom">
                      <PersonItem item={item.data} />
                    </AnimateIn>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function SectionItem({item}: any) {
  const [isOpen, setisOpen] = useState(false);

  return (
    <div className={`SectionItem ${isOpen ? 'is-open' : ''}`} onClick={() => setisOpen(!isOpen)}>
      <h3 className="SectionItem-title">{item.title}</h3>
      <div className="SectionItem-description" dangerouslySetInnerHTML={{__html: item.description}} />
    </div>
  );
}
