import React from 'react';
import {useStore} from 'effector-react';
import useSWR from 'swr';
import Head from '../../components/Head';

import {$config} from '../../models/app/app';

import './Generic.scss';
import AnimateIn from '../../components/AnimateIn';

export default function Generic({slug}: {slug: string}) {
  const {locale} = useStore($config);

  const page = useSWR(`/${slug}`).data?.items[0].data;

  return (
    <div className="Generic">
      {page && (
        <div className="dol">
          <Head title={page.title[locale]} />

          <div className="grid container --bp pad">
            <div className="rs-1 cs-1 ce-25 ce-l-5">
              <AnimateIn type="from-bottom">
                <h1 className="font-a1">{page.title[locale]}</h1>
              </AnimateIn>
            </div>

            <div className="rs-2 rs-l-1 cs-1 ce-25 cs-l-5 ce-l-21">
              <AnimateIn type="from-bottom">
                <div className="textblock" dangerouslySetInnerHTML={{__html: page.description[locale]}} />
              </AnimateIn>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
