import React from 'react';

export default function ErrorFallback({error, resetErrorBoundary}: any) {
  return (
    <div
      role="alert"
      style={{
        paddingTop: '40vh',
        height: '100vh',
        textAlign: 'center',
      }}
    >
      <div>
        <p style={{fontFamily: 'var(--light)', fontSize: '2rem', marginBottom: '1rem'}}>Something went wrong</p>
        <pre style={{fontSize: '1.25rem', marginBottom: '3rem'}}>{error.message}</pre>
        <button className="button" style={{fontSize: '1rem'}} onClick={resetErrorBoundary}>
          Try again
        </button>
      </div>
    </div>
  );
}
