import React, {useEffect, useCallback} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {BrowserRouter} from 'react-router-dom';
import i18next from 'i18next';
import axios from 'axios';
import {SWRConfig} from 'swr';
import {useStore} from 'effector-react';
import {getUrlLocale} from './lib/utils';
import settings from './config/settings';

import './i18n';

import './models/app/app_init';
import {$config, setLocale} from './models/app/app';

import ErrorFallback from './components/ErrorFallback';
import ScrollManager from './components/ScrollManager';

import AppInner from './AppInner';
import {ReactComponent as UISvg} from './config/ui.svg';

export default function App() {
  const {locale, squidexToken, isInitComplete} = useStore($config);

  const updateLocale = useCallback(() => {
    const urlLocale = getUrlLocale();
    if (urlLocale !== locale) {
      setLocale(urlLocale);
      i18next.changeLanguage(urlLocale);
    }
  }, [locale]);

  useEffect(() => {
    window.addEventListener('popstate', updateLocale);
    updateLocale();
  }, [updateLocale]);

  if (!isInitComplete) return null;

  axios.defaults.baseURL = settings.contentApi;
  axios.defaults.headers = {Authorization: `Bearer ${squidexToken}`} as any;

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <BrowserRouter basename={locale === settings.defaultLocale ? '' : locale}>
        <SWRConfig value={{fetcher: (url) => axios.get(url).then((res) => res.data)}}>
          <AppInner />
        </SWRConfig>

        <ScrollManager />
        <UISvg />
      </BrowserRouter>
    </ErrorBoundary>
  );
}
