import React, {HTMLAttributes, CSSProperties} from 'react';
import {Link} from 'react-router-dom';

type Props = {
  to: string;
  text: string;
  rest?: HTMLAttributes<HTMLLinkElement>;
};

export default function ArrowLink({to, text, ...rest}: Props) {
  return (
    <Link style={css.ArrowLink} className="ArrowLink" to={to} {...rest}>
      <span className="ArrowLink-text">{text}</span>
      <svg style={css.ArrowLink_svg} viewBox="0 0 39 39">
        <use xlinkHref="#svg-arrow-top-right" />
      </svg>
    </Link>
  );
}

const css: {[className: string]: CSSProperties} = {
  ArrowLink: {
    display: 'inline-block',
  },

  ArrowLink_svg: {
    display: 'inline',
    marginBottom: '-.1rem',
  },
};
