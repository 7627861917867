import {Config} from '../models/app/app_types';
import settings from '../config/settings';

export function getUrlLocale() {
  const {pathname} = window.location;
  const pathParts = pathname.split('/').filter(Boolean);
  let urlLocale = pathParts[0];
  if (!settings.supportedLocales.includes(urlLocale)) {
    urlLocale = settings.defaultLocale;
  }
  return urlLocale as Config['locale'];
}

export const sanitizeUppercase = (inputString: string) => {
  if (typeof inputString !== 'string') return '';
  let sanitizedString = '';

  for (let i = 0; i < inputString.length; i++) {
    sanitizedString += inputString[i]
      .replace(/ά|Ά/g, 'α')
      .replace(/έ|Έ/g, 'ε')
      .replace(/ή|Ή/g, 'η')
      .replace(/ί|Ί/g, 'ι')
      .replace(/ό|Ό/g, 'ο')
      .replace(/ύ|Ύ/g, 'υ')
      .replace(/ώ|Ώ/g, 'ω')
      .replace(/ς/g, 'σ');
  }

  return sanitizedString.toUpperCase();
};
