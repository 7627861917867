import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import useSWR from 'swr';

import './NewsArticles.scss';
import AnimateIn from '../../components/AnimateIn';
import Head from '../../components/Head';
import NewsArticlesItem from './NewsArticlesItem';

const ITEMS_PER_PAGE = 10;
const MAX_PAGES_SHOWN = 8;

interface Props {
  section: string;
}

export default function NewsArticles({section}: Props) {
  const {t} = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();

  const currentPage = Number(searchParams.get('page') || 1);

  const itemsRes = useSWR(() => {
    const SKIP = currentPage * ITEMS_PER_PAGE - ITEMS_PER_PAGE;
    return `/${section}?$top=${ITEMS_PER_PAGE}&$skip=${SKIP}&$orderby=data/date/iv desc`;
  });

  const items = itemsRes.data?.items;
  const totalItems = itemsRes.data?.total || 0;

  const paginationItems = useMemo(() => {
    const numOfPages = Math.ceil(totalItems / ITEMS_PER_PAGE);
    let pages: number[] = [];

    if (numOfPages === 1) return pages;

    if (numOfPages <= MAX_PAGES_SHOWN) {
      for (let i = 1; i <= numOfPages; i++) pages.push(i);
      return pages;
    }

    let pageBreaks: number[] = [];
    let breakIndex = 0;
    let pageFrom = 1;
    let pageTo = pageFrom + MAX_PAGES_SHOWN;

    for (let i = 1; i <= numOfPages; i++) {
      if (i > 0 && i % MAX_PAGES_SHOWN === 0) {
        pageBreaks.push(i - breakIndex * 2);
        breakIndex++;
      }
    }

    pageBreaks.forEach((pageBreak) => {
      if (currentPage >= pageBreak) {
        pageFrom = pageBreak - 1;
        pageTo = pageFrom + MAX_PAGES_SHOWN;

        if (pageTo > numOfPages) {
          pageTo = numOfPages;
          pageFrom = pageTo - MAX_PAGES_SHOWN;
        }
      }
    });

    for (let i = pageFrom; i < pageTo; i++) {
      pages.push(i);
    }

    return pages;
  }, [totalItems, currentPage]);

  const onPaginationClick = (page: number) => {
    const pageParam = page - 1 > 0 ? String(page) : null;
    setSearchParams(pageParam ? {page: pageParam} : {});
  };

  return (
    <div className="NewsArticles">
      {items && (
        <div className="dol">
          <Head title={`${t(section)} ${currentPage > 1 ? `- ${t('page')} ${currentPage}` : ''}`} description="" />

          <div className="grid container --bp pad">
            <div className="rs-1 cs-1 ce-25 ce-l-5 NewsArticles-title">
              <AnimateIn type="from-bottom">
                <h1 className="font-a1">{t(section)}</h1>
              </AnimateIn>
            </div>

            <div className="rs-2 rs-l-1 cs-1 ce-25 cs-l-5 ce-l-21 NewsArticles-items">
              <div className="news-items-grid">
                {items.map((item: any) => (
                  <AnimateIn key={item.id} type="from-bottom">
                    <NewsArticlesItem section={section} item={item.data} />
                  </AnimateIn>
                ))}
              </div>

              <div className="Pagination">
                {paginationItems.map((item) => (
                  <button
                    key={item}
                    className="Pagination-page"
                    disabled={currentPage === item}
                    onClick={() => onPaginationClick(item)}
                  >
                    <span>{item}</span>
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
