import React, {useEffect, useRef} from 'react';

export default function ESPA() {
  const ref = useRef(null);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      const isVisible = window.scrollY < window.innerHeight;

      if (ref.current) {
        const elem: HTMLDivElement = ref.current;
        if (isVisible) {
          elem.style.opacity = '1';
          elem.style.visibility = 'visible';
        } else {
          elem.style.opacity = '0';
          elem.style.visibility = 'hidden';
        }
      }
    });
  }, []);

  return (
    <div
      ref={ref}
      style={{
        position: 'fixed',
        zIndex: 500,
        right: '1rem',
        bottom: '1rem',
        width: '11.625rem',
        height: '5.5rem',
        background: `url(/espa.jpg) no-repeat`,
        backgroundSize: 'contain',
        transition: '300ms ease-out',
        transitionProperty: 'opacity, visibility',
      }}
    />
  );
}
